<script setup lang="ts">
const props = defineProps<{
	id: number
	label: string
}>()

const getColor = computed(() => {
	switch (props.id) {
		case ID_OFFER_PENDING_TO_APPROVED:
		case ID_OFFER_NEGOTIATION:
		case ID_OFFER_CONTENT_CURATION:
		case ID_OFFER_PAUSE:
			return 'orange'
		case ID_OFFER_REJECTED:
			return 'red'
		case ID_OFFER_APPROVED:
		case ID_OFFER_ACTIVE:
			return 'green'
		default:
			return 'gray'
	}
})

const text = computed<string>(() => {
	if (props.id === ID_OFFER_PENDING_TO_APPROVED) {
		return 'En revisión'
	}

	return props.label
})
</script>

<template>
	<UBadge
		:color="getColor"
		variant="soft"
	>
		{{ text }}
	</UBadge>
</template>

<style lang="scss" scoped></style>
